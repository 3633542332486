import { Avatar, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import { useNavigate } from "react-router-dom";
import { useChat } from "src/Hooks/ChatContext";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
const GymMembers = ({
  friends,
  handleAddFriend = () => {},
  type,
  hasMore,
  isLoadingMore,
  lastBookElementRef,
  loadMoreData,
  selectedFriend,
}) => {
  const navigate = useNavigate();
  const { handleAddChat } = useChat();
  const [openUnfriend, setOpenUnfriend] = useState(false);
  const [userName, setUserName] = useState({});
  const [unfriendId, setUnfriendId] = useState("");
  const [openAbout, setOpenAbout] = useState(false);

  let friends_list = friends;

  const handleOpenAbout = (value) => {
    setOpenAbout(true);
    setUserName(value);
  };

  const handleAgreeUnfriend = () => {
    handleAddFriend(unfriendId, "unfriendRequest");
    setOpenUnfriend(false);
  };

  const handleOpenUnfriend = (id) => {
    setUnfriendId(id);
    setOpenUnfriend(true);
  };

  const handleChat = (id) => {
    navigate(`/chat/?chat_id=${id}`);
    handleAddChat(id);
  };

  return (
    <div className="row">
      {friends_list && friends_list.length > 0 ? (
        <>
          {friends_list.map((item, index) => {
            return (
              <div
                className="col-12 col-md-6 col-xl-4 friend-card mt-3 d-flex justify-content-center"
                key={index}
              >
                <div className="card w-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <Avatar
                        src={s3baseUrl + item.profile_image}
                        sx={{ width: 56, height: 56 }}
                      >
                        {string_avatar(item?.first_name[0])}
                      </Avatar>
                      <div className="ms-3">
                        <h5 className="mb-0">
                          {item.first_name + " " + item.last_name}
                        </h5>
                        {item.member_biography && (
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                              handleOpenAbout(item);
                            }}
                          >
                            About Me
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-2">
                      {!selectedFriend.includes(item._id) &&
                        type === "received_request" && (
                          <Button
                            className="me-3"
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              handleAddFriend(item._id, "reject_request")
                            }
                          >
                            Reject
                          </Button>
                        )}

                      <Button
                        onClick={() => {
                          if (
                            selectedFriend.includes(item._id) ||
                            type === "sent_request"
                          ) {
                            handleAddFriend(item._id, "cancel_friend_request");
                          } else if (type === "received_request") {
                            handleAddFriend(item._id, "accept_request");
                          } else if (type === "gym_rite") {
                            handleAddFriend(item._id, "send_request");
                          } else if (type === "friends_list") {
                            handleOpenUnfriend(item._id);
                          }
                        }}
                        variant="contained"
                        disabled={
                          selectedFriend.includes(item._id) &&
                          type === "received_request"
                        }
                        size="small"
                      >
                        {type === "gym_rite"
                          ? selectedFriend.includes(item._id)
                            ? "Cancel Request"
                            : "Add Friend"
                          : type === "sent_request"
                          ? "Cancel Request"
                          : type === "received_request"
                          ? selectedFriend.includes(item._id)
                            ? "Request Accepted"
                            : "Accept Request"
                          : "Unfriend"}
                      </Button>
                      {type === "friends_list" && (
                        <Button
                          onClick={() => handleChat(item._id)}
                          variant="contained"
                          size="small"
                          className="ms-3"
                        >
                          Chat
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {hasMore ? (
            <div className="text-center mt-5">
              <span
                ref={lastBookElementRef}
                onClick={loadMoreData}
                id="load-more-feed"
              >
                {isLoadingMore ? (
                  <CircularProgress color="primary" size="1.5rem" />
                ) : (
                  "Load More"
                )}
              </span>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="col-12">
          <RecordNotFound
            title={
              type === "gym_rite"
                ? "Gym Members"
                : type === "sent_request"
                ? "Sent Request"
                : type === "received_request"
                ? "Received Request"
                : "Friends"
            }
          />
        </div>
      )}
      <CustomConfirmation
        title={"Are you sure you want to unfriend this user?"}
        open={openUnfriend}
        setOpen={setOpenUnfriend}
        handleAgree={handleAgreeUnfriend}
      />
      <GeneralPopUpModel
        open={openAbout}
        setOpen={setOpenAbout}
        title={userName.first_name + " " + userName.last_name || "About Me"}
        componentToPassDown={
          <div>
            <p>{userName.member_biography}</p>
          </div>
        }
      />
    </div>
  );
};

export default GymMembers;
