import React from "react";
import { useChat } from "../../Hooks/ChatContext";
import ChatList from "./ChatList";
import ChatRight from "./ChatRight";
import { is_small_screen } from "src/utils/constants";
import { useNavigate } from "react-router-dom";
function Chat() {
  const navigate = useNavigate();
  const { selectedChat, receiverCount } = useChat();
  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <h2>Messages</h2>
          </div>
          <div className="col-12 col-md-6 text-end position-relative">
            {receiverCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "7px",
                  backgroundColor: "#FF0000",
                  borderRadius: "50%",
                  color: "white",
                  height: "22px",
                  width: "22px",
                  textAlign: "center",
                }}
              >
                {receiverCount}
              </span>
            )}
            <button
              onClick={() => {
                navigate("/chat/friend-list");
              }}
              className="small-contained-button"
            >
              Friends
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          is_small_screen()
            ? "row w-100 chat-section m-0 mt-2"
            : "row w-100 chat-section mt-2 ps-1"
        }
      >
        {(!is_small_screen() || (is_small_screen() && !selectedChat?._id)) && (
          <ChatList />
        )}
        {(!is_small_screen() || (is_small_screen() && selectedChat?._id)) && (
          <div className="col-12 col-md-8 chat-right-part p-2">
            <ChatRight />
          </div>
        )}
      </div>
    </>
  );
}

export default Chat;
