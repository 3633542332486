import { Dialog } from "@mui/material";
import React from "react";

export default function GeneralPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            width: "100%",
          },
          className: "p-3 general-popup-model",
        }}
      >
        <div className="cross_icon_popup" onClick={() => setOpen(false)}>
          x
        </div>
        <div className="popup-title">
          <h2 style={{ fontSize: "25px" }} className="mb-0">
            {title}
          </h2>
        </div>
        <hr />
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
