import { invokeApi } from "src/bl_libs/invokeApi";

export const getAllFormsList = async (page, rows, search) => {
  const requestObj = {
    path: `api/form/form_list_for_member?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};

export const getAllFormsList_v1 = async (page, rows, data) => {
  const requestObj = {
    path: `api/form/form_list_for_member/v1?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getTemplateDetail = async (id) => {
  const requestObj = {
    path: `/api/form_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getForms_count = async (id) => {
  const requestObj = {
    path: `api/form/pending/forms_count`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addFormTemplate = async (data) => {
  const requestObj = {
    path: `/api/form_template`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editFormTemplate = async (_id, data) => {
  const requestObj = {
    path: `/api/form_template/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const submitForm = async (_id, data) => {
  const requestObj = {
    path: `api/form/submit_form/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const submitForm_v1 = async (_id, data) => {
  const requestObj = {
    path: `api/form/submit_form/v1/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getPreviewDetail = async (id) => {
  const requestObj = {
    path: `api/form/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getFormMembersDetails_V1 = async (id, user_id) => {
  const requestObj = {
    path: `api/form/get_answers_or_questions?form_id=${id}&user_id=${user_id}&feedback_type=individual`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getTemplateDetail_V1 = async (id, user_id, type) => {
  const requestObj = {
    path: `api/form/list_feedback?form_id=${id}&feedback_type=${type}${
      user_id ? `&member_id=${user_id}` : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteTemplate = async (_id) => {
  const requestObj = {
    path: `/api/form_template/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTemplatesWithoutSearchAndInActive = async (data) => {
  const requestObj = {
    path: `api/feedback_template/get_feedback_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
