import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
//

import { login } from "../DAL/Login/Login";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import AuthSocial from "../components/authentication/AuthSocial";
import { get_app_headers, project_name, s3baseUrl } from "../config/config";
import {
  ContentSettingApi,
  navbarListAPi,
} from "src/DAL/ContentSetting/ContentSetting";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  _get_is_seen_video_localStorage,
  get_page_content_localStorage,
} from "src/DAL/localStorage/localStorage";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const headers = get_app_headers();
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    projectInfo,
    handleNavbarList,
    dashboardSettingData,
    START_SOCKET_FORCEFULLY,
  } = useContentSetting();

  const content_data = get_page_content_localStorage();

  const is_seen_video = _get_is_seen_video_localStorage();
  const getContentSetting = async () => {
    const navbar_result = await navbarListAPi();
    if (navbar_result.code === 200) {
      console.log(navbar_result, "navbar_result");
      handleNavbarList(navbar_result.menus);
      localStorage.setItem(`navbar_list`, JSON.stringify(navbar_result.menus));
    }
    const result = await ContentSettingApi();
    if (result.code === 200) {
      console.log(result, "result content seetting");
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleContentSettingData({});
        handleDashboardTestimonialData([]);
        handleDashboardEventData([]);
        handleAdminTimeZone("");
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result?.content_setting?.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result?.content_setting?.client_dashboard_setting)
      );

      localStorage.setItem(`testmonial`, JSON.stringify(result?.testmonial));
      localStorage.setItem(`home_event`, JSON.stringify(result?.home_event));
      localStorage.setItem(
        `admin_time_zone`,
        JSON.stringify(result?.time_zone)
      );
      handleAdminTimeZone(result.time_zone);
      handleDashboardEventData(result.home_event);
      handleDashboardTestimonialData(result.testmonial);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );

      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmitLogin = async (data) => {
    setLoading(true);
    const result = await login(data);
    if (result.code === 200) {
      console.log(result.member, "user_data");
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result?.member?.coach)
      );
      handleUserInfo(result?.member);
      START_SOCKET_FORCEFULLY(result?.member?._id);
      handleConsultantInfo(result?.member?.coach);
      localStorage.setItem("token", result.token);
      if (is_seen_video && is_seen_video === "Yes") {
      } else {
        localStorage.setItem("is_seen_video", "No");
      }
      getContentSetting();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };
  useEffect(() => {
    // console.log(content_data, "dfkjsdhfkjah");
  }, [content_data]);
  return (
    // <div
    //   className="whole-background"
    //   style={{
    //     backgroundImage: `url(${s3baseUrl + content_data?.login_page_image})`,
    //   }}
    // >
    <RootStyle>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography
            variant="h3"
            sx={{ px: 5, mt: 10, mb: 5, color: "black" }}
          >
            Hi, Welcome Back
          </Typography>

          <div className="d-flex justify-content-center ">
            <img
              src={s3baseUrl + content_data?.login_page_image}
              alt="login"
              style={{ width: 275, height: 250 }}
            />
          </div>
        </SectionStyle>
      </MHidden>
      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: projectInfo?.login_page_text,
              }}
            ></div>
          </Typography>
          <img
            src={s3baseUrl + projectInfo?.client_background_image}
            alt="login"
          />
        </SectionStyle>
      </MHidden> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="h4" gutterBottom>
              <div
                className="p-0"
                dangerouslySetInnerHTML={{
                  __html: projectInfo?.welcome_login_page_text,
                }}
              ></div>
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              <div
                className="p-0"
                dangerouslySetInnerHTML={{
                  __html: projectInfo?.login_page_text,
                }}
              ></div>
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
    // </div>
  );
}
