import { Icon } from "@iconify/react";
import { Avatar, Badge, CircularProgress, Tooltip } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import React, { useRef, useState, useCallback } from "react";
import { s3baseUrl } from "../../config/config";
import { useChat } from "../../Hooks/ChatContext";
import { useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";
import AddIcon from "@mui/icons-material/Add";
import NewChatUsers from "./NewChatUsers";
import {
  decode_markdown,
  get_short_string,
  getTimeBasedOnDate,
  sort_array_based_on_createdAt,
} from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtmlForChat";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";

export default function ChatList() {
  const [drawerState, setDrawerState] = useState(false);
  const chat_id = new URLSearchParams(location.search).get("chat_id");
  const load_more_path = "api/chat/list_chat_by_user?page=0&limit=30";
  const observer = useRef(null);
  const [state, setState] = useState({
    search_text: "",
  });

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const {
    unreadChatCounts,
    chatList,
    handleSetSelectedChat,
    selectedChatUser,
    get_chat_list,
    handleAddChat,
    chatLoading,
    hasMorePath,
    hasTotalPages,
    hasLoading,
    setHasLoading,
  } = useChat();

  const handleClick = (chat) => {
    handleSetSelectedChat(chat);
  };

  const loadMoreData = () => {
    setHasLoading(true);
    get_chat_list(hasMorePath, state.search_text, true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (hasLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasTotalPages) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasLoading, hasTotalPages, hasMorePath, observer]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  let sortedChatList = [];

  if (chat_id) {
    sortedChatList = [
      ...chatList.filter((chat) => chat.receiver_profile.id === chat_id),
      ...chatList.filter((chat) => chat.receiver_profile.id !== chat_id),
    ];
  } else {
    sortedChatList = sort_array_based_on_createdAt(chatList);
  }

  useEffect(() => {
    get_chat_list(load_more_path, state.search_text);
  }, [state.search_text]);

  // useEffect(() => {
  //   if (chat_id) {
  //     if (chatList.length > 0) {
  //       let find_chat = chatList.find(
  //         (chat) => chat?.receiver_profile?.id == chat_id
  //       );
  //       if (find_chat) {
  //         handleSetSelectedChat(find_chat);
  //       } else {
  //         handleAddChat(chat_id);
  //       }
  //     }
  //   }
  // }, [chat_id]);

  return (
    <>
      <div className="col-12 col-md-4 chat-left-part">
        {unreadChatCounts > 0 && (
          <div className="d-flex justify-content-between mb-3">
            <div className="">Unread Chats Count</div>
            <div className="unread_chat_counts me-3">
              <Badge badgeContent={unreadChatCounts} color="error"></Badge>
            </div>
          </div>
        )}
        <div className="chat-left-header" style={{ height: "50px" }}>
          <div className="chat-search-box mt-3">
            <Icon
              fontSize={20}
              className="chat-search-icon"
              icon="akar-icons:search"
            />
            <input
              className="chat-search-input"
              type="text"
              placeholder="Search"
              value={state.search_text}
              name="search_text"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="position-relative">
          {chatLoading ? (
            <div className="chat-list d-flex justify-content-center pt-5">
              <CircularProgress size={30} />
            </div>
          ) : (
            <div className="chat-list">
              {sortedChatList.map((chat, i) => {
                return (
                  <div
                    onClick={() => handleClick(chat)}
                    className={
                      chat?.receiver_profile?.id === selectedChatUser?.id
                        ? "chat-list-user selected-chat d-flex p-2"
                        : "chat-list-user hover-effect d-flex p-2"
                    }
                    key={i}
                  >
                    <div>
                      <div className="user-profile">
                        <Avatar
                          src={s3baseUrl + chat?.receiver_profile?.profileImage}
                          style={{ height: 50, width: 50 }}
                        ></Avatar>
                        <div
                          className={
                            chat?.receiver_profile?.isOnline
                              ? "online"
                              : "offline"
                          }
                        ></div>
                      </div>
                    </div>
                    <div className="chat-profile-data">
                      <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                        {chat?.receiver_profile?.name}
                      </div>
                      <div className="chat-last-message ps-2 fs_12">
                        {chat?.message_type == "image" ? (
                          <>
                            <PhotoCameraIcon fontSize="10" /> Photo
                          </>
                        ) : chat?.message_type == "video" ? (
                          <>
                            <PlayCircleIcon fontSize="10" /> Video
                          </>
                        ) : chat?.message_type == "html" ? (
                          <>
                            <span className="new_message_text">
                              a new message received
                            </span>
                          </>
                        ) : chat?.message_type == "audio" ? (
                          <>
                            <MicIcon fontSize="18" /> Voice Message
                          </>
                        ) : (
                          chat?.lastMessage &&
                          get_short_string(
                            htmlDecode(decode_markdown(chat?.lastMessage))
                          )
                        )}
                      </div>
                    </div>
                    {chat?.lastMessageDateTime && (
                      <div className="chat-last-time text-end ps-2">
                        <Tooltip
                          title={getTimeBasedOnDate(
                            chat?.lastMessageDateTime,
                            "DD MMM YYYY [at] hh:mm A"
                          )}
                        >
                          <span className="">
                            {getTimeBasedOnDate(
                              chat?.lastMessageDateTime,
                              "DD-MM-YYYY hh:mm A"
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    {chat?.my_profile?.unread_message_count > 0 && (
                      <div className="chat-unread-count">
                        {chat?.my_profile?.unread_message_count}
                      </div>
                    )}
                  </div>
                );
              })}
              {hasTotalPages ? (
                <div className="text-center mt-3">
                  <span
                    ref={lastBookElementRef}
                    className="mt-3"
                    onClick={loadMoreData}
                    id="load-more-feed"
                  >
                    {hasLoading ? (
                      <CircularProgress color="primary" size="1.5rem" />
                    ) : (
                      "Load More"
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {!drawerState && (
            <Tooltip title="New Message" placement="top">
              <button className="new-chat-button" onClick={handleOpenDrawer}>
                <AddIcon />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`New Message`}
        componentToPassDown={
          <NewChatUsers
            onCloseDrawer={handleCloseDrawer}
            handleAddChat={handleAddChat}
          />
        }
      />
    </>
  );
}
