import React, { useEffect, useRef, useState, useCallback } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { useSnackbar } from "notistack";
import { _member_list_with_search } from "src/DAL/Chat/Chat";
import { s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import { useChat } from "src/Hooks/ChatContext";

export default function NewChatUsers({ handleAddChat, onCloseDrawer }) {
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [chatUsersList, setChatUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [moreLoad, setMoreLoad] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const load_more_path = "api/chat/users?page=0&limit=20";
  const [state, setState] = useState({
    search_text: "",
  });

  const getChatUsersList = async (path, search_text, loading) => {
    if (!loading) {
      path = load_more_path;
    }
    setSearchLoading(true);
    const result = await _member_list_with_search(path, search_text);
    if (result.code === 200) {
      setLoadMorePath(result?.load_more_url);
      setMoreLoad(result?.pagination?.hasNextPage);
      let new_coaches_list = [];
      if (result?.users?.length > 0) {
        new_coaches_list = result.users
          .filter(
            (user) =>
              user.details &&
              (Array.isArray(user.details) ? user.details.length > 0 : true)
          )
          .flatMap((user) => {
            if (user.rolse === "friends" && Array.isArray(user.details)) {
              return user.details.map((detail) => ({
                ...detail,
                full_name: `${detail.first_name} ${detail.last_name} (friend)`,
                profile_image: detail?.profile_image || "",
                email: "",
              }));
            } else {
              const details = user.details;
              if (Object.keys(details).length > 0) {
                return {
                  ...details,
                  full_name: `${details.name} (${user.role})`,
                  profile_image: details?.image?.thumbnail_1,
                };
              }
            }
          });
      }

      if (loading) {
        setChatUsersList((prevList) => [...prevList, ...new_coaches_list]);
      } else {
        setChatUsersList(new_coaches_list);
      }
      setLoading(false);
      setSearchLoading(false);
    } else {
      setLoading(false);
      setSearchLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getChatUsersList(loadMorePath, state.search_text, true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && moreLoad) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, moreLoad, loadMorePath, observer]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (!loading) {
      const timeoutId = setTimeout(() => {
        getChatUsersList(load_more_path, state.search_text);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [state.search_text]);

  useEffect(() => {
    getChatUsersList(load_more_path, state.search_text);
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="new-message-search-box mt-3">
            <input
              className="new-message-search-input"
              type="text"
              placeholder="Search"
              name="search_text"
              value={state.search_text}
              onChange={handleChange}
            />
            <div className="new-message-users-list mt-4">
              <h3 style={{ color: get_root_value("--portal-theme-primary") }}>
                Users List
              </h3>
              {searchLoading ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <CircularProgress color="primary" size="1.5rem" />
                </div>
              ) : (
                <>
                  {chatUsersList.map((user, index) => {
                    if (!!user) {
                      return (
                        <div
                          className="message-user-box p-2 mt-3"
                          onClick={() => {
                            handleAddChat(user._id, onCloseDrawer);
                            const newUrl = new URL(window.location.href);
                            newUrl.searchParams.set("chat_id", user._id);
                            window.history.replaceState(
                              null,
                              "",
                              newUrl.toString()
                            );
                          }}
                          key={index}
                        >
                          <div className="d-flex align-items-center pointer">
                            <div className="user-profile">
                              <Avatar
                                src={s3baseUrl + user?.profile_image}
                                className="message-user-avatar"
                                alt="photoURL"
                                style={{ width: 33, height: 33 }}
                              >
                                {string_avatar(user?.full_name[0])}
                              </Avatar>
                              <div
                                className={
                                  user?.is_online ? "online" : "offline"
                                }
                              ></div>
                            </div>
                            <div className="ms-2">
                              <p className="mb-0"> {user?.full_name}</p>
                              <p
                                className="mb-0"
                                style={{ fontSize: 12, color: "#969696" }}
                              >
                                {user?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              )}

              {moreLoad ? (
                <div className="text-center mt-3">
                  <span
                    ref={lastBookElementRef}
                    className="mt-3"
                    onClick={loadMoreData}
                    id="load-more-feed"
                  >
                    {isLoadingMore ? (
                      <CircularProgress color="primary" size="1.5rem" />
                    ) : (
                      "Load More"
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
