import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Label from "src/components/Label";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { getAllFormsList_v1 } from "src/DAL/Forms/forms";
import { convertToTitleCase } from "src/utils/convertHtml";
import CustomMUITable from "src/components/CustomMUITable";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import UserFormFilter from "./UserFormFilter";
import FilteredChipNew from "src/components/FilteredChipNew";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UserForms() {
  const navigate = useNavigate();
  const { userInfo } = useContentSetting();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const EMPTY_FILTER = {
    search: "",
    submission_status: "",
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getFormTemListing = async (filterData) => {
    setIsLoading(true);
    let postData = {
      submission_status: filterData.submission_status,
      search: filterData.search || "",
    };

    handleCloseFilterDrawer();
    const result = await getAllFormsList_v1(page, rowsPerPage, postData);
    if (result.code === 200) {
      let chipData = { ...filterData };
      delete chipData.search;

      let tempData = result.forms.map((item) => {
        let data = "N/A";
        if (item.createdAt) {
          data = moment(item.createdAt).format("ddd, MMM DD, YYYY");
        }
        return { ...item, MENU_OPTIONS: handleMenu(item), created_at: data };
      });

      setUpdateFilterData(chipData);
      setUserList(tempData);
      setTotalCount(result.total_forms);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    setFilterData(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    getFormTemListing(EMPTY_FILTER);
    localStorage.removeItem("UserFormSListSearch");
  };

  const handleSubmitionForm = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/forms-submition/${value.form_slug}`, {
      state: value,
    });
  };

  const handlePreview = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/forms-Details/${value._id}/${userInfo._id}`, {
      state: value,
    });
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("UserFormSListSearch", JSON.stringify(data));
    getFormTemListing(data);
    setUpdateFilterData(data);
    setFilterData(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search = searchText;
    getFormTemListing(filterData);
    localStorage.setItem("UserFormSListSearch", JSON.stringify(filterData));
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "title", label: " Title" },
    {
      id: "send_to",
      label: "Form Type",
      renderData: (data) => {
        return (
          <>
            <Stack direction="column" alignItems="start" spacing={0}>
              <Typography variant="subtitle2" noWrap>
                {data.receiver_type === "program"
                  ? data?.program?.title || "Program"
                  : "General"}
              </Typography>
              {data.receiver_type === "program" && (
                <Typography
                  sx={{ fontSize: 11, opacity: 0.5 }}
                  variant="subtitle2"
                  noWrap
                >
                  {convertToTitleCase(data.receiver_type)}
                </Typography>
              )}
            </Stack>
          </>
        );
      },
    },
    { id: "created_at", label: "Date" },
    {
      id: "submission_status",
      label: "Submission Status",
      renderData: (data) => {
        return (
          <Label
            variant="ghost"
            color={
              data.submission_status === "submitted" ? "success" : "warning"
            }
          >
            {data.submission_status === "submitted" ? `Submitted` : "Pending"}
          </Label>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (data) => (
        <Label
          variant="ghost"
          color={data.status === false ? "error" : "success"}
        >
          {data.status === false ? "InActive" : "Active"}
        </Label>
      ),
    },
  ];

  const handleMenu = (data) => {
    let MENU_OPTIONS = [];
    if (data.submission_status !== "submitted") {
      MENU_OPTIONS.push({
        label: "Submit",
        icon: "iconoir:submit-document",
        handleClick: handleSubmitionForm,
      });
    }
    MENU_OPTIONS.push({
      label: "Preview",
      icon: "pajamas:eye",
      handleClick: handlePreview,
    });
    return MENU_OPTIONS;
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("UserFormSListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search) {
        setSearchText(filter_data.search);
      }
    }

    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getFormTemListing(filter_data);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Form</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
          <div className="my-3">
            <FilteredChipNew
              data={updateFilterData}
              tempState={filterData}
              EMPTY_FILTER={EMPTY_FILTER}
              onDeleteChip={handleDeleteChip}
              onClear={handleClearFilter}
            />
          </div>
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={userList}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
            rows_per_page_count={rowsPerPage}
          />
        </div>
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <UserFormFilter
              filterData={filterData}
              handleSelectOther={handleSelectOther}
              searchFunction={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
      </div>
    </>
  );
}
